import {
  GamMediaType,
  type GamSize,
  type SizeMediaType
} from './types/api/index.js';

export function* getSizesByMediaType(
  mediaTypes: ReadonlyArray<SizeMediaType>
): Generator<GamSize, void, unknown> {
  for (const size of mediaTypes) {
    if (!size.width || !size.height) continue;

    switch (size.type) {
      case GamMediaType.Display:
      case GamMediaType.Video:
        yield [size.width, size.height];
        break;

      case GamMediaType.Native:
        yield 'fluid';
        break;

      default:
        break;
    }
  }
}
