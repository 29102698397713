import React from 'react';

import { createRoot } from 'react-dom/client';

import { updateMetricByKey } from '@client/core/atoms/metrics.js';
import { $placementsMap } from '@client/core/atoms/placements.js';
import { RenderSponsoredContentOrChildren } from '@client/sponsoredContent/RenderSponsoredContentOrChildren.js';
import {
  AdServer,
  type PlacementId,
  Placements,
  PlacementStatus
} from '@schibsted-nmp/advertising-shared';
import { getInViewSettingsByBrand } from '@client/core/utils/getInViewSettingsByPlacementId.js';

import GamAdUnit from './GamAdUnit.js';

export function renderGamAd(props: {
  placementId: PlacementId;
  containerId: string;
}) {
  const element = document.getElementById(props.containerId);
  const placementElement = element && createRoot(element);
  const inViewSettings = getInViewSettingsByBrand(props.placementId);

  if (placementElement) {
    const placements = $placementsMap.get();

    if (Object.keys(placements).length === 0) {
      console.error(`No placements found`);
      updateMetricByKey(props.placementId, PlacementStatus.NotInConfig);
      return;
    }

    const placement = placements[props.placementId]; // as ClientAdPlacement<GamPlacement>;

    if (!placement) {
      console.error(`PlacementId: ${props.placementId} not found`);
      return;
    }

    if (!Placements.Gam.is(placement)) return;

    const Children = (
      <GamAdUnit
        containerId={props.containerId}
        placement={placement}
        inViewSettings={inViewSettings}
      />
    );

    placementElement.render(
      placement.overrideAdServer === AdServer.SponsoredContent ? (
        <RenderSponsoredContentOrChildren placement={placement} {...props}>
          {Children}
        </RenderSponsoredContentOrChildren>
      ) : (
        Children
      )
    );
  }
}
