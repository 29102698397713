import { type RefObject, useCallback, useEffect, useState } from 'react';

import { $config, $initialState } from '@client/core/atoms/config.js';
import { updateMetricByKey } from '@client/core/atoms/metrics.js';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements.js';
import { getAllFeatureStatuses } from '@client/core/atoms/unleashFeatures.js';
import { useInScreen } from '@client/core/hooks/index.js';
import {
  forceBatchRequestPlacements,
  type InViewSettings
} from '@client/core/utils/getInViewSettingsByPlacementId.js';
import {
  AdPlacement,
  type ClientAdPlacement,
  debugLog,
  type GamPlacement,
  getSizesByMediaType,
  type PlacementId,
  PlacementStatus
} from '@schibsted-nmp/advertising-shared';
import { getExperimentTargetingForPlacement } from '@client/core/utils/amplitude/index.js';

import { BatchedSlots } from '../batch.js';
import { setTargetingOnSlotOrGlobal } from '../targeting.js';
import { applySizeMapping } from '../utils/sizeMapping.js';
import type { GamAdUnitProps } from './GamAdUnit.js';
import { setupGamEventListeners } from './setupGamEventListeners.js';
import { ensurePathStartsWithSlash } from './utils.js';

export function useInitiateGamUnit(
  props: GamAdUnitProps & { ref: RefObject<HTMLDivElement> }
) {
  const [placementIsInView, setPlacementIsInView] = useState(false);

  const { adServer } = $config.get() ?? {};
  const gamConfig = adServer?.gam;
  const { placement, inViewSettings } = props;
  const { placementId, status } = placement;

  const { isIntersecting } = useShouldLoadAd({
    placementId,
    ref: props.ref,
    viewSettings: inViewSettings
  });

  const [slotState, setSlotState] = useState(() =>
    getGamSlotOrNull(placementId)
  );

  /** Loads the ads in the current batch. */
  const sendToRequestBatchAds = useCallback((slot: googletag.Slot | null) => {
    if (slot) BatchedSlots.Queue.add(slot);
  }, []);

  // Some placements need to wait for a delay before they are in view, because
  // of pagination trouble etc
  useEffect(() => {
    if (status === 'refresh') {
      const placementsToWaitFor = new Array<PlacementId>(
        AdPlacement.AdvtBottom1.id
      );

      // As soon as the ad is not intersecting anymore, we will stage it to be ready to load (pending)
      if (placementsToWaitFor.includes(placementId)) {
        if (!isIntersecting) {
          updatePlacementKeyValueById(placementId, 'status', 'pending');
          setPlacementIsInView(isIntersecting);
        }
      } else {
        updatePlacementKeyValueById(placementId, 'status', 'pending');
        setPlacementIsInView(isIntersecting);
      }
    } else {
      setPlacementIsInView(isIntersecting);
    }
  }, [isIntersecting, status, placementId]);

  useEffect(() => {
    // forceBatchRequest is a special case where we want to load the ads in a batch, such as the horseshoe placements (left,right,top,wallpaper)
    // If that happens, completely ignore inView and just send them straight to request
    const isPendingInViewOrForceBatch =
      (placementIsInView && status === 'pending') ||
      status === 'forceBatchRequest';

    if (slotState && isPendingInViewOrForceBatch) {
      updatePlacementKeyValueById(placementId, 'status', 'request');
      sendToRequestBatchAds(slotState);
    }
  }, [slotState, placementIsInView, placementId, status]);

  // Set up the ad:
  useEffect(() => {
    // TODO: refactor to use Api like relevantDigital/Api.ts:
    window.googletag ??= {} as googletag.Googletag;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.googletag.cmd ??= [];

    if (!slotState) {
      // TODO: Implement consent handling with getConsentStatusOrSubscribe
      window.googletag.cmd.push(() => {
        // Check if the slot is already defined and destroy it if necessary
        if (getGamSlotOrNull(placementId)) {
          // slot already exists
          return;
        }

        debugLog('Defining Slot for placement: ', placementId);

        const slot = getSlot(placement);

        setSlotState(slot);
        updateMetricByKey(placementId, PlacementStatus.SlotCreated);
        debugLog('Gam successfully set-up for placement:', placementId);

        // Add event listeners for ad events
        setupGamEventListeners(slot);
      });
    }
  }, [placement, gamConfig, placementId, slotState]);

  return { placement, isIntersecting };
}

function getSlot(placement: ClientAdPlacement<GamPlacement>) {
  if (!window.googletag) {
    throw new Error('Google Publisher Tag not initialized');
  }

  const {
    targeting = [],
    path = '',
    sizeMappings,
    mediaTypes = []
  } = placement.adServer.config;

  const sizes = Array.from(getSizesByMediaType(mediaTypes));
  const placementConfigPath = ensurePathStartsWithSlash(path);

  const slot = window.googletag
    .defineSlot(placementConfigPath, sizes, placement.placementId)
    .addService(window.googletag.pubads());

  // Apply size mapping if available
  if (sizeMappings && sizeMappings.length > 0) {
    applySizeMapping(slot, sizeMappings, window.googletag);
  }

  if ($initialState.get().env === 'local') {
    targeting.push({ key: 'test', value: ['true'] });
  }

  /*
    Setting 'nmp_placement' to an empty string is necessary for Relevant Yield
    to be able to match the GAM ad unit with the correct placement in the
    Relevant Yield configuration for DBA. This is part of a legendary setup that's
    necessary for DBA, and we can't change it since the ad server config in
    Relevant Yield is shared with Bilbasen, at least until Bilbasen is on Aurora.
    Unfortunately, this is some legendary setup that spills over to Aurora, but
    adding it doesn't affect other the brands, so we can safely set it without
    any considerations for which brand it is:
  */
  slot.setTargeting('nmp_placement', ['']);

  const { enableGamTestCampaign } = getAllFeatureStatuses();

  if (enableGamTestCampaign) {
    targeting.push({ key: 'gamTestCampaign', value: ['true'] });
  }

  // Check for placement-specific experiment targeting
  const experimentTargeting = getExperimentTargetingForPlacement(
    placement.placementId
  );

  if (experimentTargeting) {
    debugLog(
      `Applying experiment targeting to placement ${placement.placementId}:`,
      experimentTargeting
    );

    slot.setTargeting(experimentTargeting.key, experimentTargeting.value);
  }

  if (targeting.length > 0) {
    setTargetingOnSlotOrGlobal({
      slot,
      targeting,
      global: false
    });
  }

  return slot;
}

function getGamSlotOrNull(placementId: PlacementId) {
  // TODO: refactor to use Api like relevantDigital/Api.ts:
  if (!window.googletag) {
    console.warn('Google Publisher Tag not initialized');
    return null;
  }

  // TODO: refactor to use Api like relevantDigital/Api.ts:
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window.googletag.pubads) {
    console.warn('Google Publisher Tag PubAdsService not initialized');
    return null;
  }

  const slot = window.googletag
    .pubads()
    .getSlots()
    .find((slot) => slot.getSlotElementId() === String(placementId));

  return slot ?? null;
}

function useShouldLoadAd({
  placementId,
  ref,
  viewSettings
}: {
  placementId: PlacementId;
  ref: RefObject<HTMLDivElement>;
  viewSettings: InViewSettings | undefined;
}) {
  const inViewSettings = forceBatchRequestPlacements.includes(placementId)
    ? null
    : viewSettings;

  const { isIntersecting } = useInScreen({
    ref,
    ...(inViewSettings ?? {})
  });

  return {
    isIntersecting: isIntersecting || !inViewSettings
  };
}
